<template>
  <validation-observer
    #default="{invalid}"
  >
    <div class="d-flex justify-content-center">
      <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
        <b-col class="col-12 text-center">
          <h1 class="mb-1">{{ $t('creator.add_contact_info') }}</h1>
          <p>{{ $t('creator.what_email') }}</p>
        </b-col>

        <b-col class="col-12 mt-1">
          <validation-provider
            #default="{ errors }"
            :name="$t('login.email')"
            :vid="$t('login.email')"
            rules="required|email"
          >
            <b-form-input v-model="email_contact" type="email" :class="errors.length === 0 ? 'is-valid' : 'is-invalid'" @keyup.enter="email_contact.length > 0 ? save(): ''"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-col>

        <b-col class="col-12 d-flex justify-content-center">
          <b-button :disabled="invalid" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
        </b-col>

        <b-col class="col-12 d-flex justify-content-center">
          <p>{{ $t('creator.requiredEmail') }}</p>
        </b-col>
      </b-row>
    </div>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import pitch_service from '@/services/pitch_service'
import utils from '../../../../libs/utils';

export default {
  name: 'contactCastingCall',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      utils,
      email_contact: '',
      is_editing: this.$route.params.is_editing,
    }
  },
  created() {
    this.validateReadyInfo()
    this.$emit('is_editing', this.is_editing)
  },
  methods: {
    validateReadyInfo() {
      const user_data = utils.getUserData()
      this.email_contact = this.pitch.email_contact ? this.pitch.email_contact : user_data.email
    },
    save() {
      let stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))
      if (stepper_info) {
        stepper_info.email_contact = this.email_contact
      } else {
        stepper_info = {
          'email_contact': this.email_contact
        }
      }
      localStorage.setItem('stepperInfo', JSON.stringify(stepper_info))

      const data = {
        'email_contact': this.email_contact
      }

      pitch_service.updateApplyCastingCall(this.$route.params.pitch_uuid, data).then(response => {
        this.$emit('updatePitch', response)
        if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
        else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'attachments', pitch_uuid: response.uuid}})
      })
    }
  },
}
</script>