<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.fileUp') }}</h1>
        <p>{{ $t('creator.giveBuyer') }}</p>
      </b-col>

      <b-col class="col-12 text-center">
        <div>
          <b-form-file 
            v-model="file" 
            accept=".jpeg, .jpg, .png, .mp4, .mov, .pdf"
            browse-text="Buscar" 
            class="my-2" 
            @input="hasChangeFiles"
            v-if="!loading_files"
          >
          </b-form-file>
        </div>
        <b-col v-for="(attachment) in attachments" :key="attachment.uuid" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <video 
              class="avatar-preview-sample cursor-pointer" 
              v-if="checkVideo(attachment)" 
               :src="attachment.asset_url && attachment.asset_url.length > 0 ? attachment.asset_url : getAssetUrl(attachment.file)"
              @click="clickVideo(attachment)" 
              muted
            ></video>
            <strong v-if="checkVideo(attachment)" class="text-muted">
              <a :href="attachment.asset_url && attachment.asset_url.length > 0 ? attachment.asset_url : getAssetUrl(attachment.file)" target="_blank" class="text-muted">{{ $t('creator.videoUploaded') }}</a></strong>
            <b-avatar 
              button 
              @click="clickVideo(attachment)" 
              v-if="!checkVideo(attachment)" 
              :src="attachment.asset_url && attachment.asset_url.length > 0 ? attachment.asset_url : getAssetUrl(attachment.file)"
              square 
              class="avatar-preview-sample"
            ></b-avatar>
            <strong v-if="!checkVideo(attachment)" class="text-muted">
              <a :href="attachment.asset_url && attachment.asset_url.length > 0 ? attachment.asset_url : getAssetUrl(attachment.file)" target="_blank" class="text-muted">{{ $t('creator.imageUploaded') }}</a></strong>
          </div>
          <b-button variant="flat-secondary" @click="deleteAttachment(attachment)">
            <feather-icon icon="TrashIcon" size="20"></feather-icon>
          </b-button>
        </b-col>

        <b-col v-for="(preview, index) in previews" :key="index" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between">
          <div class="d-flex align-items-center">
            <b-avatar :src="preview.preview" square class="avatar-preview-sample" :icon="isPDF(preview.name) ? 'file-text' : 'camera-video'"></b-avatar>
            <strong v-if="!preview.uuid">
              <span v-if="preview.name && preview.name.length > 0">
                {{shortText(5, preview.name)}} <span v-if="preview.name.split(' ').length > 6">...</span>
              </span>
              <span v-else class="text-muted">{{ $t('creator.noCaption') }}</span>
            </strong>
          </div>
          <b-button variant="flat-secondary" @click="deleteFromDraggable(preview)">
            <feather-icon icon="TrashIcon" size="20"></feather-icon>
          </b-button>
        </b-col>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()" :disabled="loading_files">
          <b-spinner class="spinner-uploading-file" v-if="loading_files"></b-spinner>
          {{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}
        </b-button>
      </b-col>
      <b-col class="col-12 d-flex justify-content-center">
        <p>{{$t('creator.optional')}}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BAvatar,
  BFormFile
} from 'bootstrap-vue';

import { getThumbnails } from 'video-metadata-thumbnails';
import { getUniqueIndex } from '@/libs/utils/others'
import { shortText } from '@/libs/utils/formats';
import { getAssetUrl } from '@/libs/utils/urls';
import pitch_service from '@/services/pitch_service'

export default {
  name: 'attachments',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BSpinner,
    BAvatar,
    BFormFile
  },
  data() {
    return {
      shortText,
      getUniqueIndex,
      getAssetUrl,
      loading_files: false,
      previews: [],
      file: null,
      files: [],
      attachments: [],
      files_to_delete: [],
      ready_delete_files: false,
      ready_upload_files: false,
      is_editing: this.$route.params.is_editing,
    }
  },
  created() {
    this.attachments = this.pitch.files
    this.$emit('is_editing', this.is_editing)
  },
  methods: {
    isPDF(name) {
      return name.toLowerCase().endsWith('.pdf');
    },
    checkVideo(attachment) {
      const extension = attachment.asset_url && attachment.asset_url.length > 0 ? attachment.asset_url.split('.')[attachment.asset_url.split('.').length - 1] : attachment.file.split('.')[1]
      return (extension === 'mp4' || extension === 'mov')
    },
    clickVideo(attachment) {
      const url = attachment.asset_url && attachment.asset_url.length > 0 ? attachment.asset_url : getAssetUrl(attachment.file)
      window.open(url, '_blank')
    },
    hasChangeFiles() {
      this.files.push(this.file)
      const urls_blobs = [];
      setTimeout(() => {
        const url_blob = URL.createObjectURL(this.file);
        const unique_id = getUniqueIndex();
        if (!this.file.type.includes('video')) {
          urls_blobs.push({preview: url_blob, name: this.file.name});
          this.loading_files = false
        } else {
          this.previews.push({preview: 'is_video', name: this.file.name, id: unique_id});
          const thumbnail = getThumbnails(url_blob, {
            quality: 0.6
          });
          thumbnail.then((value) => {
            const url_blob_thubnail = URL.createObjectURL(value[0].blob);
            this.previews.map((f) => {
              if (f.id === unique_id) {
                f.preview = url_blob_thubnail
              }
            });
          });
        }
        this.previews = this.previews.concat(urls_blobs);
      }, 200)
    },
    deleteFromDraggable(preview) {
      this.files = this.files.filter((item) => item.name !== preview.name);
      this.previews = this.previews.filter((item) => item.preview !== preview.preview);
    },
    deleteAttachment(attachment) {
      this.files_to_delete.push(attachment.uuid)
      const index_file = this.attachments.findIndex(file => file.uuid === attachment.uuid)
      this.attachments.splice(index_file, 1);
    },
    save() {
      this.loading_files = true;
      if (this.files.length > 0) {
        const form_data = new FormData()
        this.files.forEach(file => {
          form_data.append('files', file)
        });
        pitch_service.uploadAttachments(this.$route.params.pitch_uuid, form_data).then(response => {
          // this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: response.uuid}})
          this.ready_upload_files = true
          this.loading_files = false;
          this.$emit('updatePitch', response)
        })
      } else {
        this.ready_upload_files = true
      }

      if (this.files_to_delete.length > 0) {
        pitch_service.deleteAttachments(this.$route.params.pitch_uuid, this.files_to_delete).then(response => {
          this.ready_delete_files = true
          this.$emit('updatePitch', response)
        })
      } else {
        this.ready_delete_files = true
      }

    },
  },
  watch: {
    ready_upload_files() {
      if (this.ready_upload_files && this.ready_delete_files) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
    },
    ready_delete_files() {
      if (this.ready_upload_files && this.ready_delete_files) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
    },
  }
}
</script>
<style scoped>
.avatar-preview-sample {
  margin-right: 1em;
  width: 45px !important;
  height: 45px !important;
  object-fit: cover !important;
  border-radius: 1em !important;
}
.spinner-uploading-file {
  height: 20px;
  width: 20px;
  margin-right: 0.5em;
}
</style>