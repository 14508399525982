<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ pitch.type_pitch === 'record_video' || pitch.type_pitch === 'video' ? $t('creator.writePitch') : $t('creator.writePitchText') }}</h1>
        <p>{{ pitch.type_pitch === 'record_video' || pitch.type_pitch === 'video' ? $t('creator.shortBest') : $t('creator.shortBestText')}}</p>
      </b-col>

      <b-col class="col-12 mt-1" > 
        <b-form-textarea
          v-model="description"
          :placeholder="$t('creator.description')"
          rows="3"
          maxlength="1000"
          :state="!required_description ? '' : state_description"
          aria-describedby="input-live-help invalid-feedback-description"
        />
        <b-form-invalid-feedback id="invalid-feedback-description">
          <span v-if="description && description.length < 20">{{ $t('creator.20characteres') }}</span>
          <span v-else-if="description && description.length >= 1000">{{ $t('creator.1000characters') }}</span>
        </b-form-invalid-feedback>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="required_description && !state_description" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <p v-if="required_description">{{ pitch.type_pitch === 'record_video' || pitch.type_pitch === 'video' ? $t('creator.pitchRequired') : $t('creator.pitchRequiredText') }}</p>
      </b-col>
      <small class="mt-2 w-100 text-center text-muted" v-if="!required_description">{{ $t('creator.optional_description') }}</small>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormTextarea,
  BButton,
  BFormInvalidFeedback,
} from 'bootstrap-vue';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import pitch_service from '@/services/pitch_service'

export default {
  name: 'addDescription',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BFormInvalidFeedback,
  },
  data() {
    return {
      required_description: false,
      ready_pitch: Object.keys(this.pitch).length > 0,
      description: '',
      is_editing: this.$route.params.is_editing,
    }
  },
  computed: {
    state_description() {
      if (this.description) return this.description.length > 20 && this.description.length < 1000
      else return false
    },
  },
  created() {
    this.validateReadyInfo();
    this.$emit('is_editing', this.is_editing)
  },
  methods: {
    validateReadyInfo() {
      if (!this.ready_pitch) {
        const stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))

        if (stepper_info) {
          if (stepper_info.type_pitch) this.required_description = stepper_info.type_pitch === 'text'

          if (stepper_info.description && stepper_info.description.length > 0) this.description = stepper_info.description
        }
      } else {
        this.required_description = this.pitch.type_pitch === 'text'
        this.description = this.pitch.pitch_description ? this.pitch.pitch_description : ''
      }
    },
    save() {
      if (!this.$route.params.pitch_uuid) {
        let stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))
        if (stepper_info) {
          stepper_info.description = this.description
        } else {
          stepper_info = {
            'description': this.description
          }
        }
        localStorage.setItem('stepperInfo', JSON.stringify(stepper_info))

        pitch_service.applyCastingCall(this.casting_call.uuid, stepper_info).then(response => {
          localStorage.removeItem('stepperInfo')
          this.$emit('updatePitch', response)
          if (response.data && response.data.response.code === 'castingcall.prevApply') {
            this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
            this.$router.push({name: 'my_work', params: {section: 'pitches'}})
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('creator.pitch_applied_title'),
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: this.$t('creator.pitch_applied_text')
                }
              })
            })
          } else if (this.casting_call.questions.length > 0) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'questions', pitch_uuid: response.uuid}})
          else if (this.casting_call.type_compensation !== 'fixed') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'desired_payment', pitch_uuid: response.uuid}})
          else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'connections', pitch_uuid: response.uuid}})
        })
      } else if (this.description.length >= 0) {
        const data = {
          'description': this.description
        }
        pitch_service.updateApplyCastingCall(this.$route.params.pitch_uuid, data).then(response => {
          localStorage.removeItem('stepperInfo')
          this.$emit('updatePitch', response)
          if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
          else if (this.casting_call.questions.length > 0) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'questions', pitch_uuid: response.uuid}})
          else if (this.casting_call.type_compensation !== 'fixed') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'desired_payment', pitch_uuid: response.uuid}})
          else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'connections', pitch_uuid: response.uuid}})
        })
      } else if (this.casting_call.questions.length > 0) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'questions', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.casting_call.type_compensation !== 'fixed') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'desired_payment', pitch_uuid: this.$route.params.pitch_uuid}})
      else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'connections', pitch_uuid: this.$route.params.pitch_uuid}})
    }
  },
}
</script>