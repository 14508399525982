<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.titlePitchStep') }}</h1>
        <p>{{ $t('creator.messageToBuyer') }}</p>
      </b-col>

      <b-col 
        class="col-12 mt-1" 
        v-for="(i, index) in $t('creator.type_ptiches')" 
        :key="index"
        ref="card_radio_button_pitch"
        @click="getActive(i, index)"
      > 
        <card-radio-button :text="i.text" :second_text="i.subtitle" :icon="i.icon"></card-radio-button>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="!selected_pitch" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <p>{{ $t('creator.messagePitchRequerid') }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue';
import cardRadioButton from '@/views/components/proposals/cardRadioButton.vue';
import pitch_service from '@/services/pitch_service';

export default {
  name: 'TypePitch',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BButton,
    cardRadioButton
  },
  data() {
    return {
      selected_pitch: null,
      type_ptiches: [
        {
          "value": "record_video",
          "text": "Record Video",
          "subtitle": "Pitch using your webcam",
          "icon": "camera-video",
          "selected": false,
        },
        {
          "value": "video",
          "text": "Upload Video",
          "subtitle": "Upload a pre-recorded pitch",
          "icon": "folder",
          "selected": false
        },
        {
          "value": "text",
          "text": "Write Text",
          "subtitle": "Tell a story using text",
          "icon": "pencil-square",
          "selected": false
        },
      ],
      ready_pitch: Object.keys(this.pitch).length > 0,
      is_editing: this.$route.params.is_editing,
    }
  },
  created() {
    setTimeout(() => {
      this.validateReadyInfo()
    }, 50)
  },
  methods: {
    validateReadyInfo() {
      if (this.ready_pitch) {
        this.selected_pitch = this.pitch.type_pitch
      } else {
        const stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))

        if (stepper_info) {
          if (stepper_info.type_pitch) {
            this.selected_pitch = stepper_info.type_pitch
          }
        }
      }

      if (this.selected_pitch) {
        const index_pitch = this.type_ptiches.findIndex(pitch => pitch.value === this.selected_pitch)

        if (index_pitch > -1) {
          this.getActive(this.type_ptiches[index_pitch], index_pitch)
        }
      }
    },
    getActive(item, index) {
      this.clearActive();
      this.$refs.card_radio_button_pitch[index].classList.add('active-class-button')
      this.selected_pitch = item.value;
    },
    clearActive() {
      for (let index = 0; index < this.type_ptiches.length; index++) {
        this.$refs.card_radio_button_pitch[index].classList.remove('active-class-button')
      }
    },
    updatePitchAction() {
      const data = {'type_pitch': this.selected_pitch}
      pitch_service.updateApplyCastingCall(this.$route.params.pitch_uuid, data).then(response => {
        this.$emit('updatePitch', response)
        let destination = '';
        if (response.type_pitch === 'text') destination = 'add_description';
        else if (response.type_pitch === 'record_video') destination = 'video_record'
        else destination = 'upload_video'
        this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: destination, pitch_uuid: this.$route.params.pitch_uuid, is_editing: true}})
      });
    },
    save() {
      if (this.selected_pitch !== this.pitch.type_pitch && (this.selected_pitch === 'text' && (this.pitch.type_pitch !== 'video' || this.pitch.type_pitch !== 'record_video')) && this.is_editing) {
        this.$swal({
          title: 'Cambiar el tipo de Pitch de video a texto, reseteara el video ya subido',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: this.$t('creator.yes_accept'),
          cancelButtonText: this.$t('search.cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.updatePitchAction()
          }
        });
      } else if (this.is_editing) {
        this.updatePitchAction();
      } else {
        let stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))
        if (stepper_info) {
          stepper_info.type_pitch = this.selected_pitch
        } else {
          stepper_info = {
            'type_pitch': this.selected_pitch
          }
        }
        localStorage.setItem('stepperInfo', JSON.stringify(stepper_info))
  
        if (this.selected_pitch === 'text') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: this.$route.params.pitch_uuid}})
        else if (this.selected_pitch === 'video') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'upload_video', pitch_uuid: this.$route.params.pitch_uuid}})
        else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'video_record', pitch_uuid: this.$route.params.pitch_uuid}})
      }
    }
  },
}
</script>
<style>
.active-class-button > .card-radion-button-steps {
  background-color: rgba(114, 103, 240, 0.021) !important;
  border: 0.0625rem solid #5582f6; 
}
.active-class-button > .card-radion-button-steps > .background-left-proposal {
  background-color: #5582f6  !important;
}
</style>