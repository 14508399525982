<template>
  <div>
    <b-col class="col-12 text-center">
      <h1 class="mb-2">{{ question.question }}</h1>
    </b-col>

    <div class="d-flex justify-content-center">
      <p>{{ $t('creator.question') }}</p>
    </div>

    <div>
      <div v-if="question.type_question === 'media'">
        <b-form-file 
          v-model="file" 
          accept=".jpeg, .jpg, .png, .mp4, .mov"
          browse-text="Buscar" 
          class="my-2" 
          @input="hasChangeFiles"
          :disabled="previews.length >= 10"
        >
        </b-form-file>

        <b-col v-for="(preview, index) in previews" :key="index" class="col-12 p-0 py-2 border-bottom d-flex justify-content-between box-previews-draggable">
          <div class="d-flex align-items-center">
            <b-avatar :src="preview.preview" square class="avatar-preview-sample"></b-avatar>
            <strong v-if="!preview.uuid">
              <span v-if="preview.name.length > 0">
                {{shortText(5, preview.name)}} <span v-if="preview.name.split(' ').length > 6">...</span>
              </span>
              <span v-else class="text-muted">{{ $t('creator.noCaption')}}</span>
            </strong>
            <strong v-else class="text-muted">{{ $t('creator.imageUploaded')}}</strong>
          </div>
          <b-button variant="flat-secondary" @click="deleteFile()">
            <feather-icon icon="TrashIcon" size="20"></feather-icon>
          </b-button>
        </b-col>

        <b-col v-if="media.length > 0 && !file" class="col-12 p-0 py-2 border-bottom d-flex">
          <a :href="media" target="_blank">{{  $t('creator.viewArchive') }}</a>
        </b-col>
      </div>
      <div v-if="question.type_question === 'free_text'">
        <b-form-textarea
          v-model="answer"
          maxlength="1000"
        >
        </b-form-textarea>
      </div>
      <div v-if="question.type_question === 'radio'" class="d-flex justify-content-center">
        <b-form-group v-slot="{ ariaDescribedby }">
          <div v-for="(option, index) in options" :key="index" class="card-option d-flex justify-content-between p-0 align-items-center" @click="selectOption(option)">
            <div :class="['background-left-option d-flex align-items-center', { 'background-left-option-selected': answer === option }]">
              <span class="ml-2 ">{{ option }}</span>
            </div>
            <b-form-radio ref="radioButtons" v-model="answer" :aria-describedby="ariaDescribedby" :value="option"/>
          </div>
        </b-form-group>
      </div>

      <div v-if="question.type_question === 'checkbox'" class="d-flex justify-content-center">
          <b-form-group v-slot="{ ariaDescribedby }">
          <div v-for="(option, index) in options" :key="index" class="card-option d-flex justify-content-between p-0 align-items-center" @click="selectOptionMultiple(option)">
            <div :class="[
                'background-left-option',
                'd-flex',
                'align-items-center',
                { 'background-left-option-selected': answers_checkboxes.includes(option) }
              ]">
              <span class="ml-2 ">{{ option }}</span>
            </div>
              <b-form-checkbox
              v-model="answers_checkboxes"
              :aria-describedby="ariaDescribedby"
              :value="option"
              :key="index"
              ref="radioButtons"
            ></b-form-checkbox>
          </div>
        </b-form-group>
      </div>
    </div>

    <b-col class="text-muted col-12 d-flex justify-content-center mt-2" v-if="question.required_question">
      <small>{{ $t('creator.requiredQuestion') }}</small>
    </b-col>
    <b-col class="text-muted col-12 d-flex justify-content-center mt-2" v-else>
      <small>{{ $t('creator.optionRequest') }}</small>
    </b-col>
  </div>
</template>

<script>
import {
  BCol,
  BAvatar,
  BButton,
  BFormFile,
  BFormTextarea,
  BFormGroup,
  BFormRadio,
  BFormCheckbox
} from 'bootstrap-vue';

import { getThumbnails } from 'video-metadata-thumbnails';
import { shortText } from '@/libs/utils/formats';

export default {
  name: 'buildQuestion',
  props: {
    question: {
      type: Object,
      required: true
    }
  },
  components: {
    BCol,
    BAvatar,
    BButton,
    BFormFile,
    BFormTextarea,
    BFormGroup,
    BFormRadio,
    BFormCheckbox
  },
  data() {
    return {
      shortText,
      answer: this.question.answer,
      answers_checkboxes: this.question.answers_checkboxes,
      options: this.question.options,
      file: this.question.file,
      previews: this.question.previews,
      has_file: this.question.has_file,
      media: this.question.media
    }
  },
  created() {
    this.formatAnswer()
  },
  methods: {
    selectOptionMultiple(option) {
      const index = this.answers_checkboxes.indexOf(option);
      if (index === -1) {
        this.answers_checkboxes.push(option);
        this.applyClass(option, true);
      } else {
        this.answers_checkboxes.splice(index, 1);
        this.applyClass(option, false);
      }
    },
    applyClass(option, addClass) {
      const radioButtons = this.$refs.radioButtons || [];
      radioButtons.forEach(radioButton => {
        if (radioButton.value === option) {
          const classList = radioButton.$el.closest('.card-option').classList;
          if (addClass) {
            classList.add('card-selected');
          } else {
            classList.remove('card-selected');
          }
        }
      });
    },
    selectOption(option) {
      this.answer = option;
      const radioButtons = this.$refs.radioButtons || [];
      radioButtons.forEach(radioButton => {
        if (radioButton.value === option) {
          radioButton.$el.closest('.card-option').classList.add('card-selected');
        } else {
          radioButton.$el.closest('.card-option').classList.remove('card-selected');
        }
      });
    },
    deleteFile() {
      this.file = null
      this.previews = []
      this.has_file = false
      this.media = ''
      this.formatAnswer()
    },
    hasChangeFiles() {
      this.previews = []
      const urls_blobs = [];
      setTimeout(() => {
        const url_blob = URL.createObjectURL(this.file);
        if (!this.file.type.includes('video')) {
          urls_blobs.push({preview: url_blob, name: this.file.name});
        } else {
          const thumbnail = getThumbnails(url_blob, {
            quality: 0.6
          });
          thumbnail.then((value) => {
            const url_blob_thubnail = URL.createObjectURL(value[0].blob);
            this.previews.push({preview: url_blob_thubnail, name: this.file.name});
          });
        }
        this.previews = this.previews.concat(urls_blobs);
        this.formatAnswer()
      }, 200)
    },
    formatAnswer() {
      const answer = {
        'question_uuid': this.question.uuid,
        'answer': this.answer,
        'answers_checkboxes': this.answers_checkboxes,
        'file': this.file,
        'previews': this.previews,
        'has_file': this.has_file,
        'media': this.media
      }

      this.$emit('answer_updated', answer)
    }
  },
  watch: {
    answer() {
      this.formatAnswer()
    },
    answers_checkboxes() {
      this.formatAnswer()
    }
  }
}
</script>
<style>
.card-option {
  border-radius: 1em;
  background-color: white;
  overflow: hidden !important;
  padding: 1em;
  width: 300px;
  height: 100%;
  margin: 1em;
  border: 1px solid rgba(210, 210, 210, 0.7); 
  cursor: pointer;
}
.background-left-option {
  background-color: #dee2e6b3;
  width: 10px;
  height: 40px;
  padding: 0px;
  margin: 0px
}
.background-left-option-selected {
  background-color: #7367f0;
  width: 10px;
  height: 40px;
  padding: 0px;
  margin: 0px
}
.card-selected {
  background-color: #7267f005 !important;
  border: 1px solid #7367f0;
}
</style>