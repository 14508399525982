<template>
  <div class="d-flex justify-content-center">
    <b-row class="mt-3 col-12 col-lg-8 col-xl-5">
      <b-col class="col-12 text-center">
        <h1 class="mb-1">{{ $t('creator.likePaid') }}</h1>
        <p>{{ $t('creator.beHonest') }}</p>
      </b-col>

      <b-col> 
        <div v-if="casting_call.compensations.length > 0" class="mb-2 mt-2 justify-content-center d-flex"> 
          <b-badge pill v-for="(compensation, index) in casting_call.compensations" :key="index" variant="badge-compensation" class="badge-compensation mr-1 mb-1">

            <b-icon v-if="getCompensation(compensation.type_compensation).icon" class="custom-badge-class" :icon="getCompensation(compensation.type_compensation).icon"></b-icon>
            <b-img v-else class="icon-svg" :src="IconTypeCompetation(compensation.type_compensation)"></b-img>
            {{ getCompensation(compensation.type_compensation).text2 }}
          </b-badge>
        </div>
      </b-col>

      <b-col class="col-12 mt-1 d-flex align-items-center">
        <b-input-group prepend="$" :class="!state_desired_payment ? 'is-invalid' : 'is-valid'" @keyup.enter="desired_payment.length > 0 ? save() : ''">
          <cleave
            v-model="desired_payment"
            :options="options_number"
            :class="`form-control ${!state_desired_payment ? 'is-invalid' : 'is-valid'}`"
            :raw="false"
          />
        </b-input-group>
        <small class="ml-2">{{$t('creator.usdEu')}}</small>
      </b-col>
  
      <b-col class="col-12 d-flex justify-content-center">
        <b-button :disabled="!desired_payment" variant="blue-button-next-step" class="blue-button-next-step my-2" @click="save()">{{is_editing ? $t('creator.savePreview') : $t('creator.butonNext')}}</b-button>
      </b-col>

      <b-col class="col-12 d-flex justify-content-center">
        <p>{{ $t('creator.required') }}</p>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BIcon,
  BBadge,
  BRow,
  BCol,
  BButton,
  BInputGroup,
  BImg,
} from 'bootstrap-vue';
import Cleave from 'vue-cleave-component'
import { IconTypeCompetation } from '@/libs/utils/icons';

import pitch_service from '@/services/pitch_service'

export default {
  name: 'desiredPayment',
  props: {
    casting_call: {
      type: Object,
      required: true
    },
    pitch: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BIcon,
    BImg,
    BBadge,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    Cleave
  },
  data() {
    return {
      IconTypeCompetation,
      options_number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand'
      },
      desired_payment: '',
      ready_pitch: Object.keys(this.pitch).length > 0,
      is_editing: this.$route.params.is_editing,
    }
  },
  created() {
    this.validateReadyInfo();
    this.$emit('is_editing', this.is_editing);
  },
  computed: {
    state_desired_payment() {
      const desired = this.desired_payment.length > 0 ? this.desired_payment.replace(/,/g, '') : ''
      return desired.length > 0 && desired.length < 8
    }
  },
  methods: {
    getCompensation(compensation_value) {
      return this.$t('creator.compensation_type').find((c) => c.value === compensation_value)
    },
    validateReadyInfo() {
      if (!this.ready_pitch) {
        const stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))

        if (stepper_info) {
          if (stepper_info) {
            this.desired_payment = stepper_info.desired_payment
          }
        }
      } else {
        this.desired_payment = parseFloat(this.pitch.desired_payment) > 0 ? this.pitch.desired_payment : ''
      }
    },
    save() {
      let stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))
      if (stepper_info) {
        stepper_info.desired_payment = this.desired_payment.replace(/,/g, '')
      } else {
        stepper_info = {
          'desired_payment': this.desired_payment.replace(/,/g, '')
        }
      }
      localStorage.setItem('stepperInfo', JSON.stringify(stepper_info))

      const data = {
        'desired_payment': this.desired_payment.replace(/,/g, '')
      }

      pitch_service.updateApplyCastingCall(this.$route.params.pitch_uuid, data).then(response => {
        if (this.is_editing) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'preview', pitch_uuid: this.$route.params.pitch_uuid}})
        else this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'connections', pitch_uuid: response.uuid}})
        
        this.$emit('updatePitch', response)
      })
    }
  },
}
</script>
<style>
.badge-compensation {
  background-color: transparent;
  color: #212529;
  font-weight: 400;
  font-size: small;
  padding: 8px;
}
</style>
<style scoped>
.icon-svg {
  width: 18px;
  margin: 0px 3px 3px 0px
}
.badge i, .badge svg {
  height: 21px;
  width: 24px;
  padding: 2px;
  font-size: 18px;
  stroke-width: 3px;
  vertical-align:middle 
}
</style>