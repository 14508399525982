<template>
  <div :class="`card-radion-button-steps ${disabled ? 'disabled-radio-button-stepper' : ''}`">
    <div class="background-left-proposal"/>
    <div class="d-flex" v-if="second_text">
      <b-row>
        <b-col class="ml-2 col-1 d-flex align-items-center style-icon">
          <b-img v-if="icon_svg" :src="require(`@/assets/images/icons/${icon_svg}.svg`)" class="img-icon-svg"></b-img>
          <b-icon font-scale="2" v-else-if="icon" :icon="icon"/>
        </b-col> 
        <b-col>
          <div><h5>{{ text }}</h5></div>
          <div><p class="m-0 avenir-medium">{{ second_text }}</p></div>       
        </b-col>
      </b-row>
    </div>

    <div class="d-flex" v-else>
      {{ text }}
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BIcon, BImg, } from "bootstrap-vue";

export default {
  name: 'cardRadioButton',
  components: {
    BRow,
    BCol,
    BIcon,
    BImg,
  },

  props: {
    text: {
      type: String,
    },
    second_text: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    icon_svg: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style>
.card-radion-button-steps {
  border-radius: 1em !important;
  background-color: white;
  overflow: hidden !important;
  padding: 2em;
  border: 0.0625rem solid rgba(222,226,230,.7); 
  position: relative;
}
.card-radion-button-steps:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  cursor: pointer;
  transition: 500ms;
}
.background-left-proposal {
  background-color: #dee2e6b3;
  width: 10px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0px;
}
.style-icon{
  align-items: center;
  justify-content: center;
}
.disabled-radio-button-stepper, .disabled-radio-button-stepper h5 {
  color: #dcdcdc !important;
}
.disabled-radio-button-stepper:hover {
  box-shadow: none !important;
  cursor: auto !important;
}
</style>
<style scoped>
.img-icon-svg {
  width: 40px;
  height: 40px;
}
</style>