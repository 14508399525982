<template>
  <div>
    <slot name="body"></slot>
  </div>
</template>

<script>
export default {
  name: 'layoutStepper',
  created() {
    setTimeout(() => { 
      const header_navbar_shadow = document.getElementsByClassName('header-navbar-shadow')[0];
      header_navbar_shadow.style.setProperty('display', 'none');
      document.getElementsByClassName('nav-hover-steps')[0].classList.remove('d-none');
      this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
      this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });
    }, 5)
  },
  destroyed() {  
    const header_navbar_shadow = document.getElementsByClassName('header-navbar-shadow')[0];
    header_navbar_shadow.removeAttribute('style')
  }
}
</script>