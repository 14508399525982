<template>
  <layout-stepper :key="current_step">
    <template #body>
      <div class="d-flex">
        <b-col class="col-6 text-start">
          <b-link @click="makeRedirect()" v-if="has_prev_step && !is_editing" class="link-back">
          <feather-icon icon="ChevronLeftIcon"></feather-icon>
          {{ $t('creator.previousStep') }}
        </b-link>
        </b-col>
      
        <b-col class="col-6 text-right" v-if="ready_casting_call">
          <b-button v-b-toggle.sidebar-casting-call variant="btn-add-connection" class="btn-add-connection">{{ $t('creator.viewCasting') }}</b-button>
        </b-col>
      </div>
      <div v-if="ready_casting_call && ready_pitch_detail">
        <type-pitch @updatePitch="update_pitch" v-if="current_step === 'select_pitch_type'" :casting_call="casting_call" :pitch="pitch"/>
        <video-recorder @updatePitch="update_pitch" v-if="current_step === 'video_record'" :casting_call="casting_call" :pitch="pitch"/>
        <uploadVideo @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'upload_video'" :casting_call="casting_call" :pitch="pitch"/>
        <add-description @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'add_description'" :casting_call="casting_call" :pitch="pitch"/>
        <questions-casting-call @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'questions'" :casting_call="casting_call" :pitch="pitch"/>
        <desired-payment @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'desired_payment'" :casting_call="casting_call" :pitch="pitch"/>
        <connections-castin-call @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'connections'" :casting_call="casting_call" :pitch="pitch"/>
        <contact-casting-call @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'contact'" :casting_call="casting_call" :pitch="pitch"/>
        <attachments @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'attachments'" :casting_call="casting_call" :pitch="pitch"/>
        <preview @is_editing="isEditing" @updatePitch="update_pitch" v-if="current_step === 'preview'" :casting_call="casting_call" :pitch="pitch"/>
      </div>

      <b-sidebar v-if="ready_casting_call" id="sidebar-casting-call" sidebar-class="sidebar-lg" no-header right backdrop-variant="dark" backdrop shadow width="700px">
        <detail-casting-call :casting_call="casting_call" :is_sidebar="true"/>
      </b-sidebar>
    </template>
  </layout-stepper>
</template>

<script>
import {
  BLink,
  BCol,
  BButton,
  BSidebar,
  VBToggle
} from 'bootstrap-vue';

import layoutStepper from '@/views/components/proposals/layoutStepper.vue';
import TypePitch from '../pitch-stepper/typePitch.vue'
import uploadVideo from './uploadVideo.vue';
import addDescription from './addDescription.vue';
import desiredPayment from './desiredPayment.vue';
import connectionsCastinCall from './connectionsCastinCall.vue';
import contactCastingCall from './contactCastingCall.vue';
import questionsCastingCall from './questionsCastingCall.vue'
import attachments from './attachments.vue';
import preview from './preview.vue'
import detailCastingCall from './detailCastingCall.vue';
import videoRecorder from './videoRecorder.vue';

import casting_calls_services from '../../../../services/casting_calls';

export default {
  name: 'settingPublicProfileDahsboard',
  components: {
    BLink,
    BCol,
    BButton,
    TypePitch,
    uploadVideo,
    addDescription,
    desiredPayment,
    connectionsCastinCall,
    contactCastingCall,
    questionsCastingCall,
    attachments,
    preview,
    BSidebar,
    detailCastingCall,
    layoutStepper,
    videoRecorder,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      has_prev_step: false,
      current_step: this.$route.params.step,
      casting_call: {},
      ready_casting_call: false,
      ready_pitch_detail: true,
      current_pitch: {},
      pitch: {},
      is_editing: false,
    }
  },
  created() {
    if (this.current_step !== 'select_pitch_type') this.has_prev_step = true

    casting_calls_services.getCastingCallBySlug(this.$route.params.slug).then(response => {
      this.casting_call = response;
      this.ready_casting_call = true
    })

    if (this.$route.params.pitch_uuid) {
      this.ready_pitch_detail = true
      this.pitch = this.$route.params.pitch;
    }
  },
  destroyed() {
  },
  methods: {
    isEditing(is_editing) {
      this.is_editing = is_editing;
    },
    update_pitch(new_pitch) {
      this.pitch = new_pitch
    },
    makeRedirect() {
      const ready_pitch = Object.keys(this.pitch).length > 0

      let type_pitch = ''

      if (ready_pitch) {
        type_pitch = this.pitch.type_pitch
      } else {
        const stepper_info = JSON.parse(localStorage.getItem('stepperInfo'))
        if (stepper_info) {
          if (stepper_info.type_pitch) type_pitch = stepper_info.type_pitch
          else type_pitch = 'text'
        } else {
          type_pitch = 'text'
        }
      }
      if (this.current_step === 'add_description' && type_pitch === 'video') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'upload_video', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'add_description' && type_pitch === 'text') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'select_pitch_type', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'add_description' && type_pitch === 'record_video') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'video_record', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'upload_video') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'select_pitch_type', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'video_record') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'select_pitch_type', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'questions') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'desired_payment' && this.casting_call.questions.length > 0) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'questions', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'desired_payment') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'connections' && this.casting_call.type_compensation === 'negotiable') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'desired_payment', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'connections' && this.casting_call.type_compensation === 'fixed' && this.casting_call.questions.length > 0) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'questions', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'connections' && this.casting_call.type_compensation === 'fixed' && this.casting_call.questions.length === 0) this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'add_description', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'contact') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'connections', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'attachments') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'contact', pitch_uuid: this.$route.params.pitch_uuid}})
      else if (this.current_step === 'preview') this.$router.push({ name: 'stepper-send-pitch', params: {slug: this.$route.params.slug, step: 'attachments', pitch_uuid: this.$route.params.pitch_uuid}})
    },
  },
  watch: {
    '$route.params.step'(new_step) {
      this.current_step = new_step
      if (new_step !== 'select_pitch_type') this.has_prev_step = true
      else this.has_prev_step = false
    }
  }
}
</script>
<style scoped>
.btn-add-connection {
  color: #55a6c4 !important;
  background-color: #EEF6F9 !important;
  border-color: rgba(0,0,0,0) !important;
}
.btn-add-connection:hover {
  background-color: #55A6C4 !important;
  color: #F6FBFC !important;
}
</style>